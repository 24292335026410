import React, { useState } from "react"
import { useForm } from "react-hook-form"

async function postData(url, data) {
  // Default options are marked with *
  const response = await fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    headers: {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  })
  return response.json() // parses JSON response into native JavaScript objects
}

export default function FormComanda() {
  const { register, handleSubmit, errors } = useForm()
  const [formData, setFormData] = useState({})
  const [success, setSuccess] = useState(false)
  const onSubmit = data => {
    setFormData(data)
    postData(`/.netlify/functions/sendEmailContact`, data).then(data => {
      console.log("succes")
    })
    setSuccess(true)
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="md:flex md:items-center">
          <div class="md:w-full">
            <input
              type="text"
              placeholder="Nom"
              name="name"
              defaultValue={formData && formData.name}
              className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
              ref={register({ required: true, min: 3 })}
            />
            {errors.name && (
              <p class="text-red-500 normal-case text-md italic">
                Camp obligatori
              </p>
            )}
          </div>
        </div>
        <div class="md:flex md:items-center">
          <div class="md:w-full">
            <input
              type="email"
              placeholder="Email"
              name="email"
              defaultValue={formData && formData.email}
              className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
              ref={register({ required: true, pattern: /^\S+@.\S+$/i })}
            />
            {errors.email && (
              <p class="text-red-500 normal-case text-md italic">
                Camp obligatori
              </p>
            )}
          </div>
        </div>
        <div class="md:flex md:items-center2">
          <div class="md:w-full">
            <textarea
              placeholder="Deixa el teu comentari..."
              name="message"
              defaultValue={formData && formData.surname}
              className="w-full mt-2 font-title font-normal rounded-md text-myblack outline-none focus:text-myblack p-3 leading-tight border-gray-600 border-2"
              ref={register({ required: true, min: 3 })}
            />
            {errors.textarea && (
              <p class="text-red-500 normal-case text-md italic">
                Camp obligatori
              </p>
            )}
          </div>
        </div>
        {!success && (
          <button
            onClick={() => onSubmit}
            className="bg-malesherbes mb-2 w-1/2 font-title hover:bg-pagination uppercase text-black text-center py-2 rounded-sm px-4 mt-6"
          >
            Enviar
          </button>
        )}
        {success && <p>El teu missatge s'ha rebut correctament</p>}
      </form>
    </>
  )
}
