import React from "react"
import Layout from "../components/Layout"
import FormContact from "../components/form-contacte"
import SEO from "../components/seo"
const Contacte = () => {
  return (
    <Layout>
      <SEO
        title={`Contacte`}
        lang="ca"
        keywords={[
          "noticies",
          "blog",
          "blog de les males herbes",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          "narrativa",
          "català",
          "catalana",
          "contacte",
          "autor",
          "autora",
          "escriptora",
        ]}
      />
      <div className="flex flex-wrap">
        <div className="w-full">
          <p className="font-title font-bold uppercase text-gray-900 text-xl border-b border-black">
            Contacte
          </p>
        </div>
      </div>
      <div className="flex flex-wrap">
        <div class="flex flex-wrap mb-4 -mx-2 -my-4 mt-12">
          <div class="w-full md:w-1/2 px-4 py-3 text-lg mb-32">
            <p class="font-bold">Editorial Males Herbes:</p>
            <a href="mailto:info@editorialmalesherbes.com">
              info@editorialmalesherbes.com
            </a>
            <p>C/ Guilleries, 26, 1er 2a</p>
            <p>08012 Barcelona</p>
            <p class="font-bold mt-6">Departament de premsa:</p>
            <a href="mailto:premsa@editorialmalesherbes.com">
              premsa@editorialmalesherbes.com
            </a>
            <p class="font-bold mt-6">Distribueix:</p>
            <p>
              <a
                href="https://www.punxes.es"
                className="hover:underline hover:text-malesherbes"
              >
                Les Punxes
              </a>
            </p>
            <p class="font-bold mt-6">Recepció d'originals:</p>
            <p className="italic">
              Queda temporalment tancada la recepció d'originals, quan tornem a
              obrir-la ho notificarem aquí mateix.
            </p>
          </div>
          <div class="w-full px-4 py-4  sm:w-full md:w-1/2 ">
            {/* <Image fluid={autor.frontmatter.imatge.sharp.fluid} /> */}
            <div className="w-full bg-white h-auto">
              <FormContact />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Contacte
